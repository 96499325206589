@import '../../../scss/_definition';

.footer {
	position: fixed;
	height: $size_footer_height;
	width: 100%;
	bottom: 0;
	display: flex;
	justify-content: center;

	font-size: $font-size_small;
	border-top: 1px solid $color_highlight;
	background-color: $color_gray_light;
	z-index: 1;

	@media (max-width: $breakpoint_small) {
		position: relative;
		height: $size_footer_height * 3;
	}

	&__wrap {
		display: flex;
		flex-wrap: nowrap;
		width: 100%;
		max-width: $breakpoint_big;
		justify-content: space-between;
		align-items: center;
		margin: 0 $grid_height;

		@media (max-width: $breakpoint_medium) {
			margin-top: calc($size_footer_height / 2);
			flex-direction: column;
			align-items: center;
		}

		&__element {
			display: flex;
			white-space: nowrap;

			&--break-point-big {
				@media (max-width: $breakpoint_medium) {
					display: none;
				}
			}
		}
	}
}
