@import '../../scss/_definition';

.cc-revoke{
	display: none;
}

.cc-message, .cc-compliance{
	font-family: 'Roboto', sans-serif !important;
	font-size: $font_size_default !important;
}

.cc-window{
	background-color: $color_gray_light !important;
	border-top: 1px solid $color_highlight !important;
}
