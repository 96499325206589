@import '../../../scss/_definition';

.disclaimer {
	display: none;
	opacity: 0.95;
	background-color: $color_white;
	justify-content: center;
	align-items: center;

	&__content {
		font-size: $font_size_huge;

		&__wrap {
			max-width: 600px;
			margin: 0 $grid_gap $grid_gap $grid_gap;

			&__text {
				white-space: pre-wrap;

				& .highlight {
					color: $color_highlight;
				}
			}

			&__image {
				margin: 0 $grid_gap * 2;

				& img {
					width: 100%;
				}
			}

			&__button {
				position: relative;
				top: -$grid_gap;
				margin-top: 50px;
				cursor: pointer;

				& .button {
					background-color: transparent;
					border: 1px solid $color_gray;
					display: flex;
					justify-content: center;

					& .button__icon {
						display: none;
					}

					& .button__text__headline {
						font-size: $font_size_huge;
						color: $color_highlight;
					}
				}
			}
		}
	}
}
