$size_header_height: 165px;
$size_header_logo_width: 359px;
$size_heroImage_height: 298px;
$size_footer_height: 20px;
$size_sidebar_width: 335px;
$size_default_content: 1100px;

$breakpoint_small: 770px;
$breakpoint_medium: 1000px;
$breakpoint_big: 1920px;

$grid_height: 35px;
$grid_width: 320px;
$grid_gap: 20px;

//-------------------------------------------------------------------------

$font_size_huge: 18px;
$font_size_headline: 14px;
$font_size_default: 13px;
$font_size_small: 11px;
$text_line_height: 22px;

$icon_width_small: 13px;
$icon_width_default: 20px;

$color_gray: #5B5B5B;
$color_gray_medium: #AAAAAA;
$color_gray_light: #F0F0F0;
$color_highlight: #00ADBA;
$color_warning: #C50000;
$color_white: #FFFFFF
