@import '../../../scss/_definition';

.mixed{
	position: relative;
	height: 100%;
	overflow: hidden;

	&__background{
		position: relative;

		& img{
			width: 100%;
		}

		&__link{
			position: absolute;
			width: 100%;
			height: 100%;
			z-index: 10;
		}

		&__box{
			position: absolute;
			right: 0;
			bottom: 20px;
			left: 20px;
			width: calc(100% - 20px);

			&__background{
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				background-color: $color_white;
				opacity: 0.75;
			}

			&__text{
				position: relative;
				z-index: 1;
				text-align: end;
				margin: 10px $grid_gap;
			}
		}
	}

	&__description{
		&__headline{
			height: $grid_height;
			display: flex;
			flex-direction: column;
			justify-content: center;
		}

		&__subheadline{
			height: $grid_height;
		}

		&__text{
			line-height: $text_line_height;
		}
	}

	&__moreLink{
		position: absolute;
		bottom: 0;
		display: none;
		cursor: pointer;
	}
}
