@import '../../../scss/_definition';

.header {
	position: sticky;
	height: $size_header_height;
	top: 0;
	z-index: 100;

	display: flex;
	justify-content: center;
	background-color: $color_white;
	border-bottom: 1px solid $color_highlight;

	&__wrap {
		position: relative;
		width: $breakpoint_big;
		display: flex;
		justify-content: space-between;
		align-items: center;

		&__left {
			display: flex;
			align-items: center;
			height: $size_header_height;
			margin-left: $grid_height;

			@media (max-width: $breakpoint_small) {
				margin-left: 0;
			}

			&__image {
				width: 360px;

				@media (max-width: $breakpoint_small) {
					display: none;
				}
			}
		}

		&__right {
			position: relative;
			width: 100%;
			max-width: $size_header_logo_width;
			align-self: flex-start;
			height: $size_header_height - $size_footer_height;
			margin-top: $size_footer_height;

			@media (max-width: $breakpoint_small) {
				margin-top: 0;
			}

			&__image {
				width: 100%;
			}

			&__links {
				height: $grid_height;
				display: flex;
				justify-content: space-around;
				align-items: center;

				&__icon {
					width: $icon_width_small;
				}

				& a {
					color: $color_gray;
				}
			}
		}

		&__right__attached {
			display: flex;
			height: $grid_height;
			align-items: center;
			position: absolute;
			right: $size_header_logo_width;
			bottom: 5px;

			@media (max-width: $breakpoint_small) {
				right: -2px;
				bottom: -5px;
				text-align: end;
			}

			&__link {
				margin-right: 15px;

				&__after {
					@media (max-width: $breakpoint_small) {
						display: none;
					}
				}
			}

			&__icon {
				width: $icon_width_small;
				padding-right: 5px;

				@media (max-width: $breakpoint_small) {
					padding-right: 10px;
				}
			}
		}
	}
}
