@import '../../../scss/_definition';

.info {
	display: flex;
	align-items: flex-start;

	&__icon {
		width: $icon_width_small;
		margin: 0 10px;
	}

	&__content {
		width: 100%;

		&__headline {
			font-size: $font_size_headline;
			height: $grid_height;
			display: flex;
			flex-direction: column;
			justify-content: center;
		}

		&__text {
			min-height: $grid_height + $grid_gap;

			&__wrap {
				display: flex;
				position: relative;

				&--spaceBefore {
					margin-top: 10px;
				}

				@media (max-width: $breakpoint_small) {
					justify-content: space-between;
					width: 100%;
				}

				@media (min-width: $breakpoint_small) {
					&__inner {
						display: flex;
						width: 60%;

						&:first-child {
							width: 40%;
						}

						&--fullWidth {
							width: 100% !important;
						}
					}
				}
			}
		}
	}
}
