@import '../../../scss/_definition';

.buttonExpandable {

	&__trigger{
		cursor: pointer;
	}

	&__preview{
		transition-property: height, opacity, margin;
		transition-duration: 150ms, 200ms, 100ms;
		transition-timing-function: ease-in-out, ease-in-out;

		&--opened{
			opacity: 1;
			height: 250px;
			margin: $grid_gap 0;
			transition-delay: 0ms, 100ms;
		}

		&--closed{
			height: 0;
			opacity: 0;
			margin: 0;
			transition-delay: 150ms, 0ms;
		}

		&__text{
			white-space: pre-line;
		}

		&__link{
			display: block;
			margin-top: $grid_gap;
		}
	}
}
