@import '../../scss/_definition';

.mainContainer {
	height: 100%;
	min-height: 100%;
	display: flex;
	flex-direction: column;

	&__footer {
		background-color: $color_gray_light;

		@media (max-width: $breakpoint_small) {
			display: none;
		}

		&--mobile {
			display: none;

			@media (max-width: $breakpoint_small) {
				display: block;
				height: 120px;
				position: relative;
			}
		}
	}

	&__navigation {
		display: block;
		width: $size_sidebar_width;
		background: -webkit-linear-gradient(180deg, rgba(240, 240, 240, 1) 60%, rgba(255, 255, 255, 1) 100%);
		background: linear-gradient(180deg, rgba(240, 240, 240, 1) 60%, rgba(255, 255, 255, 1) 100%);
		flex-shrink: 0;

		@media (max-width: $breakpoint_small) {
			display: none;
			width: 100%;
			position: fixed;
			bottom: 0;
		}

		&--mobile {
			display: none;
			background: $color_gray_light !important;
			z-index: 1;

			@media (max-width: $breakpoint_small) {
				display: block;
			}
		}
	}
}

.mainContent {
	&__wrap {
		display: flex;
		justify-content: center;

		&__inner {
			width: 100%;
			max-width: $breakpoint_big;

			&__heroImage {
				position: relative;
				height: $size_heroImage_height;
				flex-shrink: 0;

				@media (max-width: $breakpoint_small) {
					height: calc($size_heroImage_height / 2);
				}

				&__image {
					position: relative;
					height: 100%;
					width: 100%;
					background-size: cover;
					background-repeat: no-repeat;
					background-position: center;
				}
			}

			&__content {
				flex: 1;
				display: flex;

				&__main {
					position: relative;
					flex-grow: 1;
					margin-left: $grid_height * 2;

					@media (max-width: $breakpoint_small) {
						margin: 0 $grid_gap;
					}

					@media (min-width: $breakpoint_small) and (max-width: $breakpoint_medium) {
						margin-left: $grid_height;
					}

					body & {
						margin-bottom: $size_footer_height + $grid_gap;
					}
				}
			}
		}
	}
}

.content {
	line-height: $text_line_height;
	margin-top: -$text_line_height;
}

.textContent {
	white-space: pre-line;
	max-width: $size_default_content;
	text-align: justify;
}

.grid {
	@supports not (display: grid) {
		display: flex;
		flex-wrap: wrap;
	}

	@supports (display: grid) {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax($grid_width, 1fr));
		grid-template-rows: 1fr;
		grid-gap: $grid_gap;
		margin-top: $grid_gap;
	}

	&__column {
		position: relative;
		@supports not (display: grid) {
			flex-grow: 1;
		}
	}
}

.ie11 .grid {
	width: 1200px;

	&__column {
		width: 280px;
		margin: 10px;
		float: left;
	}
}

#dr-flex-embed-holder {
	width: 80% !important;

	@media (max-width: $breakpoint_small) {
		width: 100% !important;
	}
}

