@import '_definition';

@font-face {
	font-family: Roboto;
	font-display: swap;
	src: url(../fonts/Roboto-Regular.ttf);
}

body{
	margin: 0;
	font-family: 'Roboto', sans-serif;
	font-size: $font_size_default;
	color: $color_gray;
	overflow-y: scroll;

	-webkit-tap-highlight-color: rgba(0,0,0,0);
}

a, .link{
	text-decoration: none;
	color: $color_highlight;

	&:hover{
		text-decoration: underline;
	}
}

a:focus, button:focus{
	border: none;
	outline: none;
	text-decoration: underline;
	color: $color_highlight;
}

p{
	color: $color_gray;
	margin: 0;
	white-space: pre-wrap;
}

h1, h2, .headline{
	font-size: $font_size_headline;
	font-weight: bold;
	color: $color_highlight;
	margin: 0;
	letter-spacing: 0.5px;
	padding-bottom: 3px;
}

h3, .subheadline{
	font-size: $font-size_default;
	font-weight: normal;
	color: $color_highlight;
	margin: 0;
}
