@import '../../../scss/_definition';

.introduction{
	margin-left: -$grid_height;

	&--noMargin{
		margin-left: 0;
	}

	&__headline{
		height: $grid_height;
		margin: $grid_gap 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	&__text {
		height: 100%;
		margin-bottom: $grid_gap;

		@media (max-width: $breakpoint_small) {
			max-height: inherit;
		}
	}
}
