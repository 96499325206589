@import '../../../scss/_definition';

.gallery{

	&__image{

		& img{
			width: 100%;
		}
	}
}
