@import '../../../scss/_definition';

.button{
	position: relative;
	height: $grid_height + $grid_gap;
	background-color: $color_gray_medium;
	padding: 0 $grid_gap;
	display: flex;
	align-items: center;
	overflow: hidden;
	border: none;
	cursor: pointer;

	&:hover{
		& .button__text__headline{
			text-decoration: underline;
		}
	}

	&__icon{
		width: $icon_width_default;
		height: $icon_width_default;
		margin-right: $icon_width_default;

		& img{
			width: 100%;
		}
	}

	&__text{
		z-index: 1;
		text-align: left;

		&__headline{
			color: $color_gray_light;
		}
	}

	&__link{
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		z-index: 1;
	}
}

.warning .button{
	border: 1px solid $color_warning;
	color: $color_warning;
	background-color: inherit;

	& h1, & h2, & h3, & h4{
		color: $color_warning;
	}
}
