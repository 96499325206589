@import '../../../scss/_definition';

.navigation {
	position: relative;
	flex-grow: 0;
	flex-shrink: 0;

	transition: height 200ms ease-in-out;

	@media (max-width: $breakpoint_small) {
		background: $color_gray_light;
		height: 330px; // Will be overwritten in navigation-mobile.js
	}

	&--closed {
		height: 0 !important;
		margin-bottom: 25px;

		@media (min-width: $breakpoint_small) {
			height: 100%;
		}
	}

	&__zertificate {
		width: 100%;
		max-width: 125px;
		padding-left: $grid_height;
	}

	&__mobile {
		display: flex;
		justify-content: center;
		height: 20px;
		border-top: 1px solid $color_highlight;
		background: $color_gray_light;

		@media (min-width: $breakpoint_small) {
			display: none;
		}

		&__burger {
			display: flex;
			justify-content: center;
			align-items: center;

			&__circle {
				position: absolute;
				height: 40px;
				width: 75px;
				top: -20px;
				border-top-left-radius: 75px;
				-moz-border-radius-topleft: 75px;
				border-top-right-radius: 75px;
				-moz-border-radius-topright: 75px;
				background-color: $color_gray_light;

				&--behind {
					border: 1px solid $color_highlight;
					top: -22px;
					z-index: -1;
				}
			}

			&__image {
				position: relative;
			}
		}
	}

	&__list {
		margin-top: $grid_gap;
		margin-bottom: 0;
		padding-left: $grid_height;
		overflow: hidden;

		@media (max-width: $breakpoint_small) {
			position: relative;
			height: 100%;
			overflow: auto;
			padding-left: 0;
		}

		&__element {
			width: 100%;
			list-style: none;
			margin-bottom: $grid_gap;
			display: flex;
			flex-direction: column;
			justify-content: center;

			@media (max-width: $breakpoint_small) {
				padding-left: 0;
				align-items: center;
				margin-bottom: 0;
			}

			& a {
				display: flex;
				flex-direction: row;
				justify-content: flex-start;
				align-items: center;
				min-height: $grid_height;
				color: $color_gray;

				& .active {
					color: $color_highlight;

					&:hover {
						text-decoration: underline;
					}
				}

				& .warning {
					color: $color_warning;
				}
			}

			&--withChilds {
				margin-bottom: 0;

				& .navigation__list {
					height: 0;
					transition: height 200ms ease-in-out;
				}
			}

			&--active .navigation__list {
				height: initial;
			}

			&__inner {
				height: 100%;
				display: flex;
				flex-direction: column;
				justify-content: center;
				font-size: $font_size_headline;

				& svg {
					height: 5px;
				}
			}
		}
	}
}
