@import '../../../scss/_definition';

.location{

	&__image{

		& img {
			width: 100%;
		}
	}
}
